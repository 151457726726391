<template>
    <div class="createLiveMarket">
        <oneStep v-show="stepActive == 1" @stepActiveChange="stepActiveChange"></oneStep>
        <twoStep v-show="stepActive == 2" @stepActiveChange="stepActiveChange"></twoStep>
        <threeStep v-show="stepActive == 3"></threeStep>
    </div>
</template>

<script>
import oneStep from "@/views/admin/liveMarket/oneStep";
import twoStep from "@/views/admin/liveMarket/twoStep";
import threeStep from "@/views/admin/liveMarket/threeStep";
export default {
    name: "createLiveMarket",
    components: {
        oneStep,
        twoStep,
        threeStep,
    },
    data() {
        return {
            stepActive: 1
        }
    },
    methods: {
        stepActiveChange(val) {
            this.stepActive = val
        }
    }
}
</script>

<style scoped lang="scss">
    .createLiveMarket {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }
</style>
<template>
  <div class="live-market-step-two">
    <div class="steps-wrapper">
      <div class="step-item current">
        <div class="step-line"></div>
        <div class="step-text">1</div>
        <div class="step-title">创建直播销售数据</div>
      </div>
      <div class="step-item current">
        <div class="step-line"></div>
        <div class="step-text">2</div>
        <div class="step-title">添加直播销售数据</div>
      </div>
      <div class="step-item">
        <div class="step-line"></div>
        <div class="step-text">3</div>
        <div class="step-title">创建成功</div>
      </div>
    </div>
    <el-form :model="ruleForm" :rules="tableRules" ref="ruleForm" label-position="top" style="margin-top: 20px">
      <el-form-item label="直播分值" prop="live_score">
        <el-input-number v-model.number="ruleForm.live_score" placeholder="0.5-100" clearable style="width: 200px" :min="0.5" :max="100" :step="0.5" :precision="1" :step-strictly="true" :controls="false"></el-input-number>
      </el-form-item>
      <el-form-item label="直播时长" prop="live_time">
        <el-time-picker v-model="ruleForm.live_time" :default-value="+new Date('2023-1-1 00:00:00')" value-format="timestamp" format="mm:ss" style="width: 200px" popper-class="time-picker"> </el-time-picker>
      </el-form-item>
      <el-form-item label="直播时长分值" prop="live_time_score">
        <el-input-number v-model.number="ruleForm.live_time_score" placeholder="0.5-100" clearable style="width: 200px" :min="0.5" :max="100" :step="0.5" :precision="1" :step-strictly="true" :controls="false"></el-input-number>
      </el-form-item>
      <el-form-item label="请添加商品数据" prop="goods_data" style="position: relative">
        <!-- <el-input v-model="ruleForm.goods_data" style="display: none"></el-input> -->
        <el-checkbox-group v-model="ruleForm.goods_data" style="display: none">
          <el-checkbox label="数据校验"></el-checkbox>
        </el-checkbox-group>
        <el-button type="primary" @click="addGoodData(1)" style="position: absolute; top: -50px; right: 0">添加商品数据</el-button>
        <template>
          <el-table :data="ruleForm.goods_data" border style="width: 100%" :cell-style="{ height: '60px', color: '#343442', fontSize: '14px', fontFamily: 'PingFang SC', fontWeight: 400 }" :header-cell-style="{ fontWeight: 500, color: '#14141C', background: 'rgba(245,245,245,1)', height: '60px' }">
            <el-table-column prop="name" label="商品信息" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="visit_average" label="浏览量"> </el-table-column>
            <el-table-column prop="click_average" label="平均点击率(%)"> </el-table-column>
            <el-table-column prop="conversion_average" label="平均转化率(%)"> </el-table-column>
            <el-table-column prop="everyday_price" label="日常价">
              <template slot-scope="scope">{{ Number(scope.row.everyday_price).toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="right_everyday_price" label="正确日常价">
              <template slot-scope="scope">{{ !scope.row.right_everyday_price ? 0 : Number(scope.row.right_everyday_price).toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="everyday_price_score" label="分值">
              <template slot-scope="scope">{{ !scope.row.everyday_price_score ? 0 : Number(scope.row.everyday_price_score).toFixed(1) }}</template>
            </el-table-column>
            <el-table-column prop="live_price" label="直播间价">
              <template slot-scope="scope">{{ Number(scope.row.live_price).toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="right_live_price" label="正确直播间价">
              <template slot-scope="scope">{{ !scope.row.right_live_price ? 0 : Number(scope.row.right_live_price).toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="live_price_score" label="分值">
              <template slot-scope="scope">{{ !scope.row.live_price_score ? 0 : Number(scope.row.live_price_score).toFixed(1) }}</template>
            </el-table-column>
            <el-table-column prop="putaway_score" label="商品上架分值">
              <template slot-scope="scope">{{ !scope.row.putaway_score ? 0 : Number(scope.row.putaway_score).toFixed(1) }}</template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <el-link type="primary" :underline="false" style="margin-right: 12px" @click="addGoodData(2, scope.row, scope.$index)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="delGoodData(scope.row, scope.$index)">删除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-form-item>
      <el-form-item label="请添加商品优惠券" prop="discount_coupon">
        <!-- <el-input v-model="ruleForm.discount_coupon" style="display: none"></el-input> -->
        <el-checkbox-group v-model="ruleForm.discount_coupon" style="display: none">
          <el-checkbox label="数据校验"></el-checkbox>
        </el-checkbox-group>
        <el-table :data="ruleForm.discount_coupon" border style="width: 100%" :cell-style="{ height: '60px', color: '#343442', fontSize: '14px', fontFamily: 'PingFang SC', fontWeight: 400 }" :header-cell-style="{ fontWeight: 500, color: '#14141C', background: 'rgba(245,245,245,1)', height: '60px' }">
          <el-table-column prop="name" label="商品信息" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="coupon_type" label="优惠券类型" width="180">
            <template slot-scope="scope">
              <el-select v-model="scope.row.coupon_type" clearable style="width: 100%">
                <el-option label="指定商品满减券" :value="1"> </el-option>
                <el-option label="指定商品打折券" :value="2"> </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="min_max" label="优惠券内容" width="360">
            <template slot-scope="scope">
              <template v-if="!!scope.row.coupon_type">
                <span>满</span>
                <el-input-number v-model.number="scope.row.max" clearable style="width: 120px; margin: 0 12px" :min="scope.row.coupon_type === 2 ? 1 : scope.row.min" :max="999999999.99" :step="0.01" :precision="2" :step-strictly="true" :controls="false"></el-input-number>
                <span>{{ scope.row.coupon_type === 1 ? "减" : "打" }}</span>
              </template>
              <template v-if="scope.row.coupon_type === 1">
                <el-input-number v-model.number="scope.row.min" clearable style="width: 120px; margin: 0 12px" :min="0.01" :max="999999999.99" :step="0.01" :precision="2" :step-strictly="true" :controls="false"></el-input-number>
                <span>元</span>
              </template>
              <template v-if="scope.row.coupon_type === 2">
                <el-input-number v-model.number="scope.row.min" clearable style="width: 120px; margin: 0 12px" :min="0.01" :max="9.99" :step="0.01" :precision="2" :step-strictly="true" :controls="false"></el-input-number>
                <span>折</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="send_num" label="发放量（张）" width="150">
            <template slot-scope="scope">
              <el-input-number v-model.number="scope.row.send_num" placeholder="1000-100000" clearable style="width: 100%" :min="1000" :max="100000" :step="1" :step-strictly="true" :controls="false"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="get_num" label="限领（张）" width="150">
            <template slot-scope="scope">
              <el-select v-model="scope.row.get_num" clearable style="width: 100%">
                <el-option label="不限" :value="0"> </el-option>
                <el-option label="1" :value="1"> </el-option>
                <el-option label="2" :value="2"> </el-option>
                <el-option label="3" :value="3"> </el-option>
                <el-option label="4" :value="4"> </el-option>
                <el-option label="5" :value="5"> </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="score" label="分值" width="150">
            <template slot-scope="scope">
              <el-input-number v-model.number="scope.row.score" placeholder="0.5-100" clearable style="width: 100%" :min="0.5" :max="100" :step="0.5" :precision="1" :step-strictly="true" :controls="false"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-link type="primary" :underline="false" v-if="scope.row.name" @click="addCoupon(scope.row)">添加优惠券</el-link>
              <el-link type="danger" :underline="false" v-else @click="delCoupon(scope.$index)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="请添加商品互动回答" prop="interact_answer">
        <!-- <el-input v-model="ruleForm.interact_answer" style="display: none"></el-input> -->
        <el-checkbox-group v-model="ruleForm.interact_answer" style="display: none">
          <el-checkbox label="数据校验"></el-checkbox>
        </el-checkbox-group>
        <el-table :data="ruleForm.interact_answer" border style="width: 100%" :cell-style="{ height: '60px', color: '#343442', fontSize: '14px', fontFamily: 'PingFang SC', fontWeight: 400 }" :header-cell-style="{ fontWeight: 500, color: '#14141C', background: 'rgba(245,245,245,1)', height: '60px' }">
          <el-table-column prop="name" label="商品信息" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="content" label="提问问题">
            <template slot-scope="scope">
              <el-input v-model="scope.row.content" :min="2" :max="200"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="count" label="出现次数" width="300">
            <template slot-scope="scope">
              <el-input-number v-model.number="scope.row.count" placeholder="1-10" style="width: 100%" clearable :min="1" :max="10" :step="1" :step-strictly="true" :controls="false"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-link type="primary" :underline="false" v-if="scope.row.name" @click="addQuestion(scope.row)">添加问题</el-link>
              <el-link type="danger" :underline="false" v-else @click="delQuestion(scope.$index)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="弹幕设置" prop="barrage" style="position: relative">
        <!-- <el-input v-model="ruleForm.barrage" style="display: none"></el-input> -->
        <el-checkbox-group v-model="ruleForm.barrage" style="display: none">
          <el-checkbox label="数据校验"></el-checkbox>
        </el-checkbox-group>
        <el-button type="primary" style="position: absolute; top: -50px; right: 0" @click="addBarrage()">添加弹幕</el-button>
        <el-table :data="ruleForm.barrage" border style="width: 100%" :cell-style="{ height: '60px', color: '#343442', fontSize: '14px', fontFamily: 'PingFang SC', fontWeight: 400 }" :header-cell-style="{ fontWeight: 500, color: '#14141C', background: 'rgba(245,245,245,1)', height: '60px' }">
          <el-table-column prop="content" label="弹幕">
            <template slot-scope="scope">
              <el-input v-model="scope.row.content" :min="2" :max="200"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="count" label="出现次数">
            <template slot-scope="scope">
              <el-input-number v-model.number="scope.row.count" placeholder="1-10" style="width: 100%" clearable :min="1" :max="10" :step="1" :step-strictly="true" :controls="false"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-link type="danger" :underline="false" @click="delBarrage(scope.$index)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <div class="btn-footer">
          <el-button @click="goBack">上一步</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">下一步</el-button>
        </div>
      </el-form-item>
    </el-form>
    <!-- 弹窗 -->
    <el-dialog title="添加商品数据" :visible.sync="dialogVisible" width="800px" @closed="cancelGoodData('goodForm')" :close-on-click-modal="false" top="3vh">
      <el-form :model="goodForm" :rules="goodrules" ref="goodForm" label-width="0px">
        <el-row :gutter="30">
          <el-col :span="9">
            <el-form-item label="行业分类" prop="category">
              <el-select v-model="goodForm.category" placeholder="请选择行业分类" style="width: 100%" @change="selectIndustry">
                <el-option v-for="item in industryOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择商品" prop="goods_id">
              <el-select v-model="goodForm.goods_id" placeholder="请选择商品" style="width: 100%" @change="selectGood">
                <el-option v-for="item in goodOptions" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id" :disabled="choiceGoods.includes(item.goods_id)"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="浏览量" prop="visit_val">
              <el-input-number v-model.number="goodForm.visit_val" placeholder="10000-100000" style="width: 100%" :min="10000" :max="100000" :step="1" :step-strictly="true" :controls="false"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style="display: flex; align-items: flex-end">
              <el-form-item label="波动范围(%)" prop="visit_min">
                <el-input-number v-model.number="goodForm.visit_min" placeholder="-99-99" style="width: 150px" :min="-99" :max="goodForm.visit_max ? goodForm.visit_max - 1 : 99" :step="1" :step-strictly="true" :controls="false"></el-input-number>
                <span style="margin: 0 10px">至</span>
              </el-form-item>
              <el-form-item prop="visit_max">
                <el-input-number v-model.number="goodForm.visit_max" placeholder="-99-99" style="width: 150px" :min="goodForm.visit_min ? goodForm.visit_min + 1 : -99" :max="99" :step="1" :step-strictly="true" :controls="false"></el-input-number>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="9">
            <el-form-item label="点击率(%)" prop="click_val">
              <el-input-number v-model.number="goodForm.click_val" placeholder="0.01-10" style="width: 100%" :min="0.01" :max="10" :step="0.01" :precision="2" :step-strictly="true" :controls="false"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style="display: flex; align-items: flex-end">
              <el-form-item label="波动范围(%)" prop="click_min">
                <el-input-number v-model.number="goodForm.click_min" placeholder="-99-99" style="width: 150px" :min="-99" :max="goodForm.click_max ? goodForm.click_max - 1 : 99" :step="1" :step-strictly="true" :controls="false"></el-input-number>
                <span style="margin: 0 10px">至</span>
              </el-form-item>
              <el-form-item prop="click_max">
                <el-input-number v-model.number="goodForm.click_max" placeholder="-99-99" style="width: 150px" :min="goodForm.click_min ? goodForm.click_min + 1 : -99" :max="99" :step="1" :step-strictly="true" :controls="false"></el-input-number>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="9">
            <el-form-item label="转化率(%)" prop="conversion_val">
              <el-input-number v-model.number="goodForm.conversion_val" placeholder="0.01-10" style="width: 100%" :min="0.01" :max="10" :step="0.01" :precision="2" :step-strictly="true" :controls="false"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style="display: flex; align-items: flex-end">
              <el-form-item label="波动范围(%)" prop="conversion_min">
                <el-input-number v-model.number="goodForm.conversion_min" placeholder="-99-99" style="width: 150px" :min="-99" :max="goodForm.conversion_max ? goodForm.conversion_max - 1 : 99" :step="1" :step-strictly="true" :controls="false"></el-input-number>
                <span style="margin: 0 10px">至</span>
              </el-form-item>
              <el-form-item prop="conversion_max">
                <el-input-number v-model.number="goodForm.conversion_max" placeholder="-99-99" style="width: 150px" :min="goodForm.conversion_min ? goodForm.conversion_min + 1 : -99" :max="99" :step="1" :step-strictly="true" :controls="false"></el-input-number>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="21">
            <el-form-item label="商品标题" prop="name">
              <el-input v-model="goodForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="日常价" prop="everyday_price">
              <el-input-number v-model.number="goodForm.everyday_price" placeholder="0.01-999999999.99" clearable style="width: 200px" :min="0.01" :max="999999999.99" :step="0.01" :precision="2" :step-strictly="true" :controls="false"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="正确日常价" prop="right_everyday_price">
              <el-input-number v-model.number="goodForm.right_everyday_price" placeholder="0.01-999999999.99" clearable style="width: 200px" :min="0.01" :max="999999999.99" :step="0.01" :precision="2" :step-strictly="true" :controls="false"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="分值" prop="everyday_price_score">
              <el-input-number v-model.number="goodForm.everyday_price_score" placeholder="0.5-100" clearable style="width: 200px" :min="0" :max="100" :step="0.5" :precision="1" :step-strictly="true" :controls="false"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="直播间价" prop="live_price">
              <el-input-number v-model.number="goodForm.live_price" placeholder="0.01-999999999.99" clearable style="width: 200px" :min="0.01" :max="999999999.99" :step="0.01" :precision="2" :step-strictly="true" :controls="false"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="正确直播间价" prop="right_live_price">
              <el-input-number v-model.number="goodForm.right_live_price" placeholder="0.01-999999999.99" clearable style="width: 200px" :min="0.01" :max="999999999.99" :step="0.01" :precision="2" :step-strictly="true" :controls="false"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="分值" prop="live_price_score">
              <el-input-number v-model.number="goodForm.live_price_score" placeholder="0.5-100" clearable style="width: 200px" :min="0" :max="100" :step="0.5" :precision="1" :step-strictly="true" :controls="false"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="商品上架分值" prop="putaway_score">
              <el-input-number v-model.number="goodForm.putaway_score" placeholder="0.5-100" clearable style="width: 200px" :min="0" :max="100" :step="0.5" :precision="1" :step-strictly="true" :controls="false"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div style="width: 95%; height: 40px">
              <el-button type="primary" @click="sureGoodData('goodForm')" style="float: right; margin-left: 12px">确定</el-button>
              <el-button @click="cancelGoodData('goodForm')" style="float: right">取消</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getGoodList } from "@/utils/apis";

export default {
  name: "twoStep",
  data() {
    return {
      ruleForm: {
        type: 2,
        token: void 0,
        live_score: void 0,
        live_time: void 0,
        live_time_score: void 0,
        goods_data: [],
        discount_coupon: [],
        interact_answer: [],
        barrage: [],
      },
      tableRules: {
        live_score: [{ required: true, message: "请输入直播分值", trigger: "blur" }],
        live_time: [{ required: true, message: "请选择直播时长", trigger: "blur" }],
        live_time_score: [{ required: true, message: "请输入直播时长分值", trigger: "blur" }],
        goods_data: [{ required: true, message: "请添加商品数据", trigger: "change" }],
        discount_coupon: [{ required: true, message: "请添加商品优惠券", trigger: "change" }],
        interact_answer: [{ required: true, message: "请添加商品互动回答", trigger: "change" }],
        barrage: [{ required: true, message: "请添加弹幕设置", trigger: "blur" }],
      },
      goodForm: {
        category: void 0,
        goods_id: void 0,
        name: void 0,
        everyday_price: void 0,
        right_everyday_price: void 0,
        everyday_price_score: void 0,
        live_price: void 0,
        right_live_price: void 0,
        live_price_score: void 0,
        putaway_score: void 0,
        visit_val: void 0,
        visit_min: void 0,
        visit_max: void 0,
        click_val: void 0,
        click_min: void 0,
        click_max: void 0,
        conversion_val: void 0,
        conversion_min: void 0,
        conversion_max: void 0,
      },
      goodrules: {
        // category: [{ required: true, message: "请选择行业分类", trigger: "blur" }],
        goods_id: [{ required: true, message: "请选择商品", trigger: ["blur", "change"] }],
        name: [
          { required: true, message: "请输入商品标题", trigger: ["blur", "change"] },
          { min: 2, max: 50, message: "长度在 2 到 50 个字", trigger: "blur" },
        ],
        everyday_price: [{ required: true, message: "请输入日常价", trigger: ["blur", "change"] }],
        // right_everyday_price: [{ required: true, message: "请输入正确日常价", trigger: "blur" }],
        // everyday_price_score: [{ required: true, message: "请输入分值", trigger: "blur" }],
        live_price: [{ required: true, message: "请输入直播间价", trigger: ["blur", "change"] }],
        // right_live_price: [{ required: true, message: "请输入正确直播间价", trigger: "blur" }],
        // live_price_score: [{ required: true, message: "请输入分值", trigger: "blur" }],
        // putaway_score: [{ required: true, message: "请输入商品上架分值", trigger: "blur" }],
        visit_val: [{ required: true, message: "请输入浏览量", trigger: "blur" }],
        visit_min: [{ required: true, message: "请输入浏览量最低波动范围", trigger: "blur" }],
        visit_max: [{ required: true, message: "请输入浏览量最高波动范围", trigger: "blur" }],
        click_val: [{ required: true, message: "请输入点击率", trigger: "blur" }],
        click_min: [{ required: true, message: "请输入点击率最低波动范围", trigger: "blur" }],
        click_max: [{ required: true, message: "请输入点击率最高波动范围", trigger: "blur" }],
        conversion_val: [{ required: true, message: "请输入转化率", trigger: "blur" }],
        conversion_min: [{ required: true, message: "请输入转化率最低波动范围", trigger: "blur" }],
        conversion_max: [{ required: true, message: "请输入转化率最高波动范围", trigger: "blur" }],
      },
      industryOptions: [],
      goodOptions: [],
      dialogVisible: false,
      isEdit: false,
      currentIndex: void 0,
      currentGood: void 0,
      choiceGoods: [],
    };
  },
  methods: {
    getIndustryOptions() {
      this.$http.axiosGetBy(this.$api.categoryList, {}, (res) => {
        if (res.code === 200) {
          this.industryOptions = res.data;
          this.industryOptions.unshift({ id: 0, name: " " });
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    selectIndustry(id) {
      this.goodForm.category = id;
      this.getGoodOptions();
    },
    selectGood(id) {
      if (!!id) {
        let goodInfo = this.goodOptions.find((item) => item.goods_id === id);
        this.goodForm.name = goodInfo.goods_name;
        this.goodForm.everyday_price = goodInfo.goods_price;
        this.goodForm.live_price = goodInfo.goods_promotion_price;
      }
    },
    async getGoodOptions() {
      return new Promise((resolve, reject) => {
        this.$http.axiosGetBy(
          this.$api.showGoods,
          { type: "all", category_id: this.goodForm.category },
          (res) => {
            if (res.code === 200) {
              this.goodOptions = res.data;
              resolve(res.data);
            } else {
              reject(new Error(`${res.msg}`));
            }
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    async addGoodData(type, item, index) {
      type === 2 ? (this.goodForm.category = item.category) : (this.goodForm.category = void 0);
      Promise.all([this.getGoodOptions()]).then((res) => {
        this.choiceGoods = this.ruleForm.goods_data.map((item) => item.goods_id);
        if (type === 1) {
          this.isEdit = false;
          Object.keys(this.goodForm).map((key) => (this.goodForm[key] = void 0));
        } else {
          let $index = this.choiceGoods.findIndex((id) => id === item.goods_id);
          if ($index !== -1) this.choiceGoods.splice($index, 1);
          this.isEdit = true;
          Object.keys(this.goodForm).map((key) => (this.goodForm[key] = item[key]));
          this.currentIndex = index;
          this.currentGood = item;
        }
        if ((type === 2 && !!this.goodForm.goods_id) || type === 1) this.dialogVisible = true;
      });
    },
    delGoodData(item, index) {
      this.ruleForm.goods_data.splice(index, 1);
      this.ruleForm.discount_coupon = this.ruleForm.discount_coupon.filter((data) => data.goods_id !== item.goods_id);
      this.ruleForm.interact_answer = this.ruleForm.interact_answer.filter((data) => data.goods_id !== item.goods_id);
    },
    cancelGoodData(formName) {
      if (this.currentGood?.goods_id) this.choiceGoods = [...this.choiceGoods, ...[this.currentGood.goods_id]];
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    getAverage(args) {
      return args.reduce((a, b) => a + b) / args.length;
    },
    sureGoodData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.goodForm.visit_data = new Array(30).fill().map((v, i) => {
            return Number((((Math.random() * (this.goodForm.visit_max - this.goodForm.visit_min + 1) + this.goodForm.visit_min) / 100 + 1) * this.goodForm.visit_val).toFixed(0));
          });
          this.goodForm.click_data = new Array(30).fill().map((v, i) => {
            return Number((((Math.random() * (this.goodForm.click_max - this.goodForm.click_min + 1) + this.goodForm.click_min) / 100 + 1) * this.goodForm.click_val).toFixed(2));
          });
          this.goodForm.conversion_data = new Array(30).fill().map((v, i) => {
            return Number((((Math.random() * (this.goodForm.conversion_max - this.goodForm.conversion_min + 1) + this.goodForm.conversion_min) / 100 + 1) * this.goodForm.conversion_val).toFixed(2));
          });
          this.goodForm.visit_average = this.getAverage(this.goodForm.visit_data).toFixed(0);
          this.goodForm.click_average = this.getAverage(this.goodForm.click_data).toFixed(2);
          this.goodForm.conversion_average = this.getAverage(this.goodForm.conversion_data).toFixed(2);
          if (!!this.isEdit) {
            this.ruleForm.goods_data.splice(this.currentIndex, 1, { ...JSON.parse(JSON.stringify(this.goodForm)), ...{ coupon: [], question: [] } });
            let couponIndex = this.ruleForm.discount_coupon.findIndex((data) => data.goods_id === this.currentGood.goods_id);
            let questionIndex = this.ruleForm.interact_answer.findIndex((data) => data.goods_id === this.currentGood.goods_id);
            let couponLastIndex = this.ruleForm.discount_coupon.findLastIndex((data) => data.goods_id === this.currentGood.goods_id);
            let questionLastIndex = this.ruleForm.interact_answer.findLastIndex((data) => data.goods_id === this.currentGood.goods_id);
            if (couponIndex !== -1) this.$set(this.ruleForm.discount_coupon[couponIndex], "name", this.goodForm.name);
            if (questionIndex !== -1) this.$set(this.ruleForm.interact_answer[questionIndex], "name", this.goodForm.name);
            if (!this.goodForm.everyday_price_score || !this.goodForm.live_price_score || !this.goodForm.putaway_score) {
              let index = this.ruleForm.goods_data.findIndex((item) => item.goods_id === this.goodForm.goods_id);
              this.ruleForm.goods_data[index].everyday_price_score = 0;
              this.ruleForm.goods_data[index].live_price_score = 0;
              this.ruleForm.goods_data[index].putaway_score = 0;
              this.ruleForm.discount_coupon.splice(couponIndex, couponLastIndex - couponIndex + 1);
              this.ruleForm.interact_answer.splice(questionIndex, questionLastIndex - questionIndex + 1);
            } else {
              if (couponIndex === -1) this.ruleForm.discount_coupon.push({ goods_id: this.goodForm.goods_id, name: this.goodForm.name, coupon_type: void 0, min_max: [], min: void 0, max: void 0, send_num: void 0, get_num: void 0, score: void 0 });
              if (questionIndex === -1) this.ruleForm.interact_answer.push({ goods_id: this.goodForm.goods_id, name: this.goodForm.name, content: void 0, count: void 0 });
            }
          } else {
            this.ruleForm.goods_data.push({ ...JSON.parse(JSON.stringify(this.goodForm)), ...{ coupon: [], question: [] } });
            if (!!this.goodForm.everyday_price_score && !!this.goodForm.live_price_score && !!this.goodForm.putaway_score) {
              this.ruleForm.discount_coupon.push({ goods_id: this.goodForm.goods_id, name: this.goodForm.name, coupon_type: void 0, min_max: [], min: void 0, max: void 0, send_num: void 0, get_num: void 0, score: void 0 });
              this.ruleForm.interact_answer.push({ goods_id: this.goodForm.goods_id, name: this.goodForm.name, content: void 0, count: void 0 });
            } else {
              let index = this.ruleForm.goods_data.findIndex((item) => item.goods_id === this.goodForm.goods_id);
              this.ruleForm.goods_data[index].everyday_price_score = 0;
              this.ruleForm.goods_data[index].live_price_score = 0;
              this.ruleForm.goods_data[index].putaway_score = 0;
            }
          }
          this.dialogVisible = false;
        }
      });
    },
    addCoupon(item) {
      let index = this.ruleForm.discount_coupon.findIndex((data) => data.name === item.name && data.goods_id === item.goods_id);
      if (this.ruleForm.discount_coupon.length === 1) {
        this.ruleForm.discount_coupon.push({ goods_id: item.goods_id, coupon_type: void 0, min_max: [], min: void 0, max: void 0, send_num: void 0, get_num: void 0, score: void 0 });
      } else {
        this.ruleForm.discount_coupon.splice(index + 1, 0, { goods_id: item.goods_id, coupon_type: void 0, min_max: [], min: void 0, max: void 0, send_num: void 0, get_num: void 0, score: void 0 });
      }
    },
    delCoupon(index) {
      this.ruleForm.discount_coupon.splice(index, 1);
    },
    addQuestion(item) {
      let index = this.ruleForm.interact_answer.findIndex((data) => data.name === item.name && data.goods_id === item.goods_id);
      if (this.ruleForm.interact_answer.length === 1) {
        this.ruleForm.interact_answer.push({ goods_id: item.goods_id, content: void 0, count: void 0 });
      } else {
        this.ruleForm.interact_answer.splice(index + 1, 0, { goods_id: item.goods_id, content: void 0, count: void 0 });
      }
    },
    delQuestion(index) {
      this.ruleForm.interact_answer.splice(index, 1);
    },
    addBarrage(type, index, item) {
      this.ruleForm.barrage.push({ content: void 0, count: void 0 });
    },
    delBarrage(index) {
      this.ruleForm.barrage.splice(index, 1);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.ruleForm));
          params.goods_data.map((item) => {
            if (!item.everyday_price_score) item.everyday_price_score = 0;
            if (!item.live_price_score) item.right_everyday_price = 0;
            if (!item.live_price_score) item.live_price_score = 0;
            if (!item.putaway_score) item.right_live_price = 0;
            if (!item.putaway_score) item.putaway_score = 0;
            let couponArr = [];
            let questionArr = [];
            params.discount_coupon.map((coupon) => {
              if (item.goods_id === coupon.goods_id) couponArr.push(coupon);
            });
            item.coupon = couponArr;
            params.interact_answer.map((interact) => {
              if (!interact.content || !interact.count) {
                this.$message.error("请完善商品互动回答数据！");
                throw new Error();
              }
              if (item.goods_id === interact.goods_id) questionArr.push(interact);
            });
            item.question = questionArr;
            params.barrage.map((data) => {
              if (!data.content || !data.count) {
                this.$message.error("请完善弹幕设置数据！");
                throw new Error();
              }
            });
          });
          params.live_time = (params.live_time - new Date("2023-1-1 00:00:00")) / 1000;
          params.token = sessionStorage.getItem("liveOneStepToken");
          this.$http.axiosPost(this.$api.live_saleOperate, params, (res) => {
            if (res.code === 200) {
              this.$emit("stepActiveChange", 3);
            } else {
              this.$message.warning(res.msg);
            }
          });
        }
      });
    },
    goBack() {
      this.$emit("stepActiveChange", 1);
    },
    getLiveSaleInfo() {
      let params = { type: "2", id: this.$route.query.id };
      this.$http.axiosGetBy(this.$api.live_saleInfo, params, (res) => {
        if (res.code == 200) {
          this.ruleForm = res.data;
          let coupon = [];
          let question = [];
          this.ruleForm.goods_data.map((good) => {
            good.visit_average = this.getAverage(good.visit_data).toFixed(0);
            good.click_average = this.getAverage(good.click_data).toFixed(2);
            good.conversion_average = this.getAverage(good.conversion_data).toFixed(2);
            this.choiceGoods.push(good.goods_id);
            if (!!good.coupon.length) good.coupon[0].name = good.name;
            good.coupon.map((data) => (data.goods_id = good.goods_id));
            if (!!good.question.length) good.question[0].name = good.name;
            good.question.map((data) => (data.goods_id = good.goods_id));
            coupon = coupon.concat(good.coupon);
            question = question.concat(good.question);
          });
          this.ruleForm.live_time = this.ruleForm.live_time * 1000 + new Date("2023-1-1 00:00:00").getTime();
          this.$set(this.ruleForm, "discount_coupon", coupon);
          this.$set(this.ruleForm, "interact_answer", question);
        }
      });
    },
  },
  mounted() {
    this.getIndustryOptions();
    if (!!this.$route.query.id) this.getLiveSaleInfo();
  },
  computed: {
    listenChange() {
      const category = this.goodForm.category;
      const goodOptions = this.goodOptions;
      return { category, goodOptions };
    },
  },
  watch: {
    listenChange(val) {
      let selectGoods = val.goodOptions.map((item) => item.goods_id);
      setTimeout(() => {
        if (!selectGoods.includes(this.goodForm.goods_id)) {
          this.goodForm.goods_id = void 0;
          this.goodForm.name = void 0;
          this.goodForm.everyday_price = void 0;
          this.goodForm.live_price = void 0;
        }
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.live-market-step-two {
  padding: 20px 20px 60px 20px;
  .btn-footer {
    position: absolute;
    bottom: -60px;
    left: 0;
    right: 0;
    text-align: center;
  }
  :deep .el-button--primary {
    background-color: #1122d8;
    border: #1122d8;
  }
  :deep .el-form-item__label {
    float: none;
  }
}
</style>
